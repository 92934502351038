<template>
  <div class="container ipxx-content">
    <b-form-group
      label="Locale Switch"
      label-for="selectLocale"
      label-size="lg"
    >
      <b-form-select
        id="selectLocale"
        v-model="$i18n.locale"
        class="w-auto"
      >
        <option
          v-for="(language, i) in languages"
          :key="`lang_${i}`"
          :value="language"
        >
          {{ $t(`languages.${language}`) }}
        </option>
      </b-form-select>
    </b-form-group>
    <hr>
    <h1>i18n-Test</h1>
    <div>
      <p><b>i18n testing --- titles.error:</b></p>
      <p>{{ $t('titles.login_error') }}</p>
    </div>
    <hr>
    <h1>REST-Test</h1>
    <ipxx-rest-test />
    <hr>
    <h1>Start login error</h1>
    <ipxx-start-error />
    <hr>
    <h1>Azure login error</h1>
    <ipxx-azure-error />
    <hr>
    <ipxx-register-test />
    <hr>
    <ipxx-assign-account />
  </div>
</template>

<script>
import IpxxAssignAccount from '@/components/AssignAccountForm';
import IpxxAzureError from '@/components/tests/AzureLoginErrorTest';
import IpxxRestTest from '@/components/tests/RestTest';
import IpxxRegisterTest from '@/components/RegisterForm';
import IpxxStartError from '@/components/tests/StartErrorTest';

export default {
  name: 'TestPage',
  components: {
    IpxxAssignAccount,
    IpxxAzureError,
    IpxxRestTest,
    IpxxRegisterTest,
    IpxxStartError,
  },
  data() {
    return {
      languages: ['de', 'en'],
    };
  },
};
</script>
