<template>
  <div>
    <b-btn-group>
      <b-btn
        class="mr-1"
        @click="setProviderError"
      >
        Test provider error
      </b-btn>
      <b-btn
        class="mr-1"
        @click="setUnknownError"
      >
        Test unknown error
      </b-btn>
      <b-btn
        class="mr-1"
        @click="setResetErrors"
      >
        Reset all errors
      </b-btn>
    </b-btn-group>
    <ipxx-start-login
      v-if="showComponent"
      :key="componentKey"
    />
  </div>
</template>

<script>
import IpxxStartLogin from '../../views/Start';

export default {
  name: 'StartErrorTest',
  components: {
    IpxxStartLogin,
  },
  data() {
    return {
      componentKey: 0,
      showComponent: false,
    };
  },
  beforeDestroy() {
    this.$store.dispatch('resetErrors');
  },
  methods: {
    setProviderError() {
      this.$store.dispatch('resetErrors')
        .then(() => {
          this.$http.get('/setProviderError')
            .then(() => {
              this.componentKey += 1;
              this.showComponent = true;
            })
            .catch((error) => {
              this.$logger.log('Error', error);
            });
        });
    },
    setResetErrors() {
      this.$store.dispatch('resetErrors');
    },
    setUnknownError() {
      this.$store.dispatch('resetErrors')
        .then(() => {
          this.$http.get('/setError')
            .catch(() => {
              this.componentKey += 1;
              this.showComponent = true;
            });
        });
    },
  },
};
</script>
