<template>
  <div class="rest-test">
    <b-btn-group>
      <b-btn
        class="mr-1"
        @click="testHelloRoute"
      >
        Call 'hello'
      </b-btn>
      <b-btn
        class="mr-1"
        @click="testAssignRoute"
      >
        Call 'account/assign'
      </b-btn>
    </b-btn-group>
    <p
      v-show="message.length"
      class="alert"
      :class="isSuccess ? 'alert-success' : 'alert-error'"
    >
      {{ message }}
    </p>
  </div>
</template>

<script>
export default {
  name: 'RestTest',
  data() {
    return {
      isSuccess: false,
      message: '',
    };
  },
  methods: {
    /**
     * Resets the test component.
     */
    reset() {
      this.message = '';
    },
    /**
     * Tests sending the account/assign request with the http client.
     */
    testAssignRoute() {
      this.reset();
      this.$http.post(this.$routes.account_assign)
        .then((response) => {
          this.isSuccess = true;
          this.message = response.message;
        })
        .catch((error) => {
          this.isSuccess = false;
          this.message = error;
        });
    },
    /**
     * Tests sending a simple request with the http client.
     */
    testHelloRoute() {
      this.reset();
      this.$http.get(this.$routes.hello)
        .then((response) => {
          this.isSuccess = true;
          this.message = response.data;
        })
        .catch((error) => {
          this.isSuccess = false;
          this.message = error;
        });
    },
  },
};
</script>
