<template>
  <ipxx-password-forgot
    class="my-5"
  />
</template>

<script>
import IpxxPasswordForgot from '@/components/PasswordForgot';

export default {
  name: 'PasswordForgotFormTest',
  components: { IpxxPasswordForgot },
};
</script>
