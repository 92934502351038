<template>
  <div>
    <b-btn-group>
      <b-btn
        class="mr-1"
        @click="setAccountError"
      >
        Test tenant-Id error
      </b-btn>
    </b-btn-group>
    <ipxx-azure-login
      v-if="showComponent"
      :key="componentKey"
      :query="query"
    />
  </div>
</template>

<script>
import IpxxAzureLogin from '../AzureLogin';

export default {
  name: 'AzureLoginErrorTest',
  components: {
    IpxxAzureLogin,
  },
  data() {
    return {
      componentKey: 0,
      showComponent: false,
      query: {
        code: 'test',
      },
    };
  },
  beforeDestroy() {
    this.$store.dispatch('resetErrors');
  },
  methods: {
    setAccountError() {
      this.$store.dispatch('resetErrors')
        .then(() => {
          this.$http.get('/setAccountError')
            .then(() => {
              this.componentKey += 1;
              this.showComponent = true;
            })
            .catch((error) => {
              this.$logger.log('Error', error);
            });
        });
    },
  },
};
</script>
